/** 情報ダイアログのヘッダ */
.info-modal-header {
    background-color: #2dbedb;
    color: #ffffff;
}

/** 確認ダイアログのヘッダ */
.confirm-modal-header {
    background-color: #2dbedb;
    color: #ffffff;
}

/** エラーダイアログのヘッダ */
.error-modal-header {
    background-color: #fd5479;
    color: #ffffff;
}
