.wj-control .wj-input-group .wj-form-control:not([disabled]) {
    min-height: 37px;
    font-size: 16px;
    border-radius:4px;
}

input.wj-form-control.wj-numeric {
    color:#565656 !important;
    border: 1px solid #E3E3E3 !important;
    border-radius:4px;
    
}

.wj-control .wj-input-group .wj-form-control[disabled] {
    min-height: 37px;
    font-size: 16px;
    background-color: #F5F5F5;
    color: #888888;
    cursor: not-allowed;
    border-radius:4px;
}

div.calendar {
    border: 1px solid #E3E3E3;
    color: #888888
}

.wj-calendar {
    max-width: 21em;
    font-size: 14px;
}

.wj-calendar .wj-header {
    color: white;
    background: #808080;
}

.wj-calendar .date-holiday:not(.wj-state-selected) {
    font-weight: bold;
    color: orangered;
}

.wj-calendar .date-weekend:not(.wj-state-selected) {
    color: darkblue;
}

.wj-calendar .wj-calendar-month td {
    cursor: pointer;
}

