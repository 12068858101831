.login-base {
    position: relative;
    height: 100vh;
    min-height: 300px;
    width: 100vw;
    background: #e1ebdf;
}

.loginlogo {
    margin-left: auto;
    /* IE以外用の中央寄せ */
    margin-right: auto;
    /* IE以外用の中央寄せ */
    text-align: center;
    width: 100px;
    transform: translateX(60%);
}