/** 見積基盤再計算ボタン */
.recalc_button {
  padding: 0px 20px 0px 20px;
  margin: 0px;
  height: 21px !important;
  font-size: 14px !important;
  display: inline-flex !important;
  align-items: center !important;
}

.tanka_upd_cell {
  color: #150af5 !important;
  font-weight: bold !important;
}
